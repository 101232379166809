export default {
  form: {
    name: 'Nome*',
    surname: 'Cognome*',
    email: 'E-mail*',
    company: 'Azienda*',
    agency: 'Agenzia*',
    phone: 'Telefono',
    portfolio: 'Link Portfolio',
    portfolioOptional: 'Altro Link',
    fileUpload: 'CV in formato PDF*',
    fileUploadTxt: 'CARICA IL TUO CURRICULUM',
    presentation: 'Testo di presentazione',
    message: 'Descrivi il tuo progetto / richiesta*',
    privacyCaption:
      'Ai sensi e per gli effetti del Reg. UE 2016/679 - GDPR presto il consenso al trattamento dei miei dati personali come da <a href="/it/privacy-policy" target="_blank" class="link-underline-1">informativa sulla privacy</a>*',
    newsletterCaption: 'Inviatemi aggiornamenti sulle magie della CGI',
    submitCTA: 'INVIA RICHIESTA',
    genericError: 'Campo non compilato correttamente',
    successPageTitle: 'Grazie',
    successPageText:
      'Il modulo è stato inviato correttamente, verrai contattato dal team il prima possibile.',
    failurePageTitle: 'Siamo spiacenti',
    failurePageText:
      "Si sono verificati degli errori nell'invio del modulo. Vi preghiamo di riprovare più tardi.",
  },
  careers: {
    formCTA: 'Invia la tua candidatura',
    formSubtitle: '<strong>Requisiti e competenze</strong>',
    formCaption:
      'Se pensi di essere la persona che fa al caso nostro, compila il form e invia la tua cadidatura.',
    otherPositionsTitle: 'Altre posizioni aperte',
    postinApiId: 'e2209090-6264-4d73-803b-dd85af69e909',
  },
  seoSuccess: {
    title: 'Richiesta inviata',
  },
  seoFailure: {
    title: 'Errore!',
  },
  newsletter: {
    successTitle: 'Benvenuto nella nostra newsletter!',
    successText:
      'Da oggi, sarai tra i primi a scoprire cosa c’è dietro la magia di Bluemotion.',
    seo: {
      title: 'Iscrizione alla newsletter completata',
    },
  },
}
